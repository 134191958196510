.container {
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    object-fit: contain;
    text-align: center;
    width:220px;
    height: 200px;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    border-radius: 8px;
    /* transition: background 0.5s ease; */
  }
  
  .container:hover .overlay {
    display: block;
    background: rgba(34, 34, 34, .8);
  }
  
  .forImg {
    position: absolute;
    left: 0;
  }
  
  .button{
      opacity:0;
      position: absolute;
      top:43%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
  }
  
  .container:hover .button {
    opacity: 1;
  }
  
  .dot {
  height: .8rem;
  width: .8rem;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  margin-right:.6rem
}